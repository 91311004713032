import { Card, CardProps, Text } from '@chakra-ui/react'
import CopyToClipboard from 'components/CopyToClipboard'

type HashCardProps = {
  hash: string
  textVariant?: string
  isCopiable?: boolean
} & CardProps
const HashCard = ({ hash, textVariant = 'code', isCopiable = false, ...props }: HashCardProps) => (
  <Card
    p={4}
    bgColor="lightGray"
    border="1px solid"
    borderColor="gray"
    maxH={200}
    overflow="auto"
    {...props}
  >
    {isCopiable ? (
      <CopyToClipboard text={hash} textColor="darkGray" variant={textVariant} />
    ) : (
      <Text variant={textVariant} color="darkGray">
        {hash}
      </Text>
    )}
  </Card>
)

export default HashCard
