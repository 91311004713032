import React from 'react'
import { Button } from '@chakra-ui/react'
import {
  ApiKey,
  useApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteAllApiKeysMutation,
  useDeleteApiKeyMutation,
  useUpdateApiKeyMutation,
} from 'apollo/generated/graphqlClient'
import { useSignIn } from 'hooks/useSignIn'
import { useSession } from 'next-auth/react'
import { useAccount, useConnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MAX_LIMIT_API_KEYS } from 'constants/common'
import CreateApiKey from 'components/ApiKeysModal/states/CreateApiKey'
import EditApiKey from 'components/ApiKeysModal/states/EditApiKey'
import ViewApiKeys from 'components/ApiKeysModal/states/ViewApiKeys'
import Modal from 'components/Modal'

const MUTATION_OPTIONS = { refetchQueries: ['ApiKeys'] }

const MODAL_STATES = {
  view: 'view',
  edit: 'edit',
  create: 'create',
}

type ApiKeysModalProps = {
  isOpen: boolean
  onClose: () => void
}

const ApiKeysModal = ({ isOpen, onClose }: ApiKeysModalProps) => {
  const [editedKey, setEditedKey] = React.useState<ApiKey>(null)
  const [createdKeyName, setCreatedKeyName] = React.useState('')
  const [modalState, setModalState] = React.useState(MODAL_STATES.view)

  const signIn = useSignIn()
  const { address, isConnected } = useAccount()
  const { data: session } = useSession()
  const { connect } = useConnect({ connector: new InjectedConnector() })
  const isAuthenticated = Boolean(session)

  const { data, loading: areApiKeysLoading } = useApiKeysQuery({
    skip: !isAuthenticated || !isOpen,
  })

  const [createApiKey, { loading: isCreatingApiKey }] = useCreateApiKeyMutation(MUTATION_OPTIONS)
  const [updateApiKey, { loading: isUpdatingApiKey }] = useUpdateApiKeyMutation()
  const [deleteApiKey, { loading: isDeletingApiKey }] = useDeleteApiKeyMutation(MUTATION_OPTIONS)
  const [deleteAllApiKeys, { loading: areDeletingApiKeys }] =
    useDeleteAllApiKeysMutation(MUTATION_OPTIONS)

  const refreshStates = () => {
    setEditedKey(null)
    setCreatedKeyName('')
    setModalState(MODAL_STATES.view)
  }

  const handleClose = () => {
    onClose()
    refreshStates()
  }

  const modalProps = {
    view: {
      header: {
        hasBackButton: false,
        title: 'API Keys',
      },
      ...(isAuthenticated && {
        primaryButton: {
          isDisabled: data?.apiKeys.length >= MAX_LIMIT_API_KEYS,
          label: 'Create new API Key',
          onClick: () => {
            setModalState(MODAL_STATES.create)
          },
        },
      }),
      secondaryButton: {
        label: isAuthenticated && data?.apiKeys.length > 0 ? 'Delete all API Keys' : 'Cancel',
        isLoading: isDeletingApiKey || areDeletingApiKeys,
        onClick:
          isAuthenticated && data?.apiKeys.length > 0
            ? async () => {
                const shouldDelete = confirm('Are you sure you want to delete all API keys?')

                if (shouldDelete) {
                  await deleteAllApiKeys()
                }
              }
            : handleClose,
      },
    },
    edit: {
      header: {
        hasBackButton: true,
        title: 'Edit API Key',
      },
      primaryButton: {
        label: 'Save changes',
        isDisabled: editedKey?.name.trim() === '',
        isLoading: isUpdatingApiKey,
        onClick: async () => {
          await updateApiKey({
            variables: { input: { name: editedKey.name, id: editedKey.id } },
          })
          setEditedKey(null)
          setModalState(MODAL_STATES.view)
        },
      },
      secondaryButton: {
        label: 'Cancel',
        onClick: handleClose,
      },
    },
    create: {
      header: {
        hasBackButton: true,
        title: 'Create API Key',
      },
      primaryButton: {
        label: 'Create new API Key',
        isDisabled: createdKeyName.trim() === '',
        isLoading: isCreatingApiKey,
        onClick: async () => {
          await createApiKey({ variables: { input: { name: createdKeyName } } })
          setCreatedKeyName('')
          setModalState(MODAL_STATES.view)
        },
      },
      secondaryButton: {
        label: 'Cancel',
        onClick: handleClose,
      },
    },
  }

  return (
    <Modal
      isOpen={isOpen}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      title={modalProps[modalState].header.title}
      loadingText="Creating"
      onClose={handleClose}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      onSecondaryButtonClick={modalProps[modalState].secondaryButton.onClick}
      {...(modalState === MODAL_STATES.view &&
        isAuthenticated &&
        data?.apiKeys.length === 0 && {
          hasSecondaryButton: false,
        })}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      secondaryButtonLabel={modalProps[modalState].secondaryButton.label}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...(modalProps[modalState].header.hasBackButton && {
        onBackButtonClick: refreshStates,
      })}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      isSecondaryButtonLoading={modalProps[modalState].secondaryButton.isLoading}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...(modalProps[modalState].header.hasBackButton && {
        onBackButtonClick: refreshStates,
      })}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      {...(modalProps[modalState].primaryButton
        ? {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            primaryButtonLabel: modalProps[modalState].primaryButton.label,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            isPrimaryButtonDisabled: modalProps[modalState].primaryButton.isDisabled,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            isPrimaryButtonLoading: modalProps[modalState].primaryButton.isLoading,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            onPrimaryButtonClick: modalProps[modalState].primaryButton.onClick,
          }
        : {
            customPrimaryButton: (
              <Button
                onClick={(event) => {
                  event.preventDefault()
                  if (isConnected) {
                    void signIn()
                  } else {
                    connect()
                  }
                }}
              >
                {isConnected ? 'Sign in' : 'Open Metamask'}
              </Button>
            ),
          })}
    >
      {modalState === MODAL_STATES.view && (
        <ViewApiKeys
          apiKeys={data?.apiKeys ?? []}
          areApiKeysLoading={areApiKeysLoading}
          isDeletingApiKey={isDeletingApiKey || areDeletingApiKeys}
          address={address}
          isConnected={isConnected}
          isAuthenticated={isAuthenticated}
          onEditButtonClick={(apiKey) => {
            setEditedKey(apiKey)
            setModalState(MODAL_STATES.edit)
          }}
          onRemoveButtonClick={async ({ id }) => {
            const shouldDelete = confirm('Are you sure you want to delete this API key?')

            if (shouldDelete) {
              await deleteApiKey({ variables: { input: { id } } })
            }
          }}
        />
      )}
      {modalState === MODAL_STATES.edit && (
        <EditApiKey
          editedKey={editedKey}
          onEditedKeyChange={(event) => {
            setEditedKey((prevKey) => ({ ...prevKey, name: event.target.value }))
          }}
        />
      )}
      {modalState === MODAL_STATES.create && (
        <CreateApiKey
          name={createdKeyName}
          onNameChange={(event) => {
            setCreatedKeyName(event.target.value)
          }}
        />
      )}
    </Modal>
  )
}

export default ApiKeysModal
