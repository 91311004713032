import { useToast } from 'hooks/useToast'
import { getCsrfToken, signIn } from 'next-auth/react'
import { SiweMessage } from 'siwe'
import { useAccount, useNetwork, useSignMessage } from 'wagmi'
import { handleError } from 'utils/sentry'

export const useSignIn = () => {
  const { signMessageAsync } = useSignMessage()
  const { chain } = useNetwork()
  const { address } = useAccount()
  const toast = useToast()

  const handleLogin = async () => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in with Ethereum to the app.',
        uri: window.location.origin,
        version: '1',
        chainId: chain?.id,
        nonce: await getCsrfToken(),
      })
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      })
      void signIn('credentials', {
        message: JSON.stringify(message),
        signature,
        redirect: false,
      })
    } catch (error) {
      handleError(error)
      toast({ message: 'Login failed. Try again.', status: 'danger' })
    }
  }

  return handleLogin
}
