import { ChevronDownIcon } from '@chakra-ui/icons'
import { Flex, Link, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react'
import { PAGE_OFFSET_X } from 'lib/chakra/theme'
import { Routes } from 'constants/routes'
import ApiKeysModal from 'components/ApiKeysModal'
import SearchBar from 'components/SearchBar'

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
}

const APPEARANCE = {
  [VARIANTS.primary]: {
    bgColor: 'white',
    color: 'black',
  },
  [VARIANTS.secondary]: {
    bgColor: 'secondary',
    color: 'white',
  },
}

type NavBarProps = {
  variant?: keyof typeof VARIANTS
}

const NavBar = ({ variant = 'primary' }: NavBarProps) => {
  const { bgColor, color } = APPEARANCE[variant]
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex
      px={PAGE_OFFSET_X}
      py={4}
      alignItems="center"
      justifyContent="end"
      bgColor={bgColor}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.08)"
    >
      {variant === 'secondary' && <SearchBar flex={1} mx={8} />}
      <Flex gap={6}>
        <Link color={color} fontSize="sm" href={Routes.home()}>
          Home
        </Link>
        <Menu>
          <MenuButton color={color} fontSize="sm">
            Blockchain <ChevronDownIcon color={color} />
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} href={Routes.transactions()}>
              View Latest L2 Txns
            </MenuItem>
            <MenuItem as={Link} href={Routes.transactionsEnqueued()}>
              View Latest L1 {'->'} L2 Txns
            </MenuItem>
            <MenuItem as={Link} href={Routes.transactionsExit()}>
              View Latest L2 {'->'} L1 Txns
            </MenuItem>
            <MenuItem as={Link} href={Routes.blocks()}>
              View L2 Blocks
            </MenuItem>
            <MenuItem as={Link} href={Routes.batches()}>
              View L1 Txn Batches
            </MenuItem>
          </MenuList>
        </Menu>
        <Link href={Routes.tokens()} color={color} fontSize="sm">
          Tokens
        </Link>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={onOpen} color={color} fontSize="sm">
          API Keys
        </Link>
      </Flex>
      <ApiKeysModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default NavBar
