import { Input } from '@chakra-ui/react'
import Modal from 'components/Modal'

type CreateApiKeyType = {
  name: string
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CreateApiKey = ({ name, onNameChange }: CreateApiKeyType) => (
  <Modal.Field
    name="API Key name"
    content={
      <Input
        color="darkGray"
        size="sm"
        borderColor="gray"
        borderRadius={6}
        value={name}
        placeholder="e.g. Web3 Project"
        onChange={onNameChange}
      />
    }
  />
)

export default CreateApiKey
