import { Flex, Text } from '@chakra-ui/react'
import { PAGE_OFFSET_X } from 'lib/chakra/theme'

const Footer = () => (
  <Flex
    px={PAGE_OFFSET_X}
    py={9}
    bgColor="backgroundAlt"
    alignItems="center"
    justifyContent="end"
    mt="auto"
    boxShadow="inset 0px 3px 6px -3px rgba(0, 0, 0, 0.1)"
  >
    <Flex>
      <Text color="titanGray">Terms of service</Text>
      <Text ml={4} color="titanGray">
        Network status
      </Text>
    </Flex>
  </Flex>
)

export default Footer
