import { Input } from '@chakra-ui/react'
import { ApiKey } from 'apollo/generated/graphqlClient'
import HashCard from 'components/HashCard'
import Modal from 'components/Modal'

type EditApiKeyType = {
  editedKey: ApiKey
  onEditedKeyChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const EditApiKey = ({ editedKey, onEditedKeyChange }: EditApiKeyType) => (
  <>
    <Modal.Field
      name="API Key"
      content={<HashCard hash={editedKey.key} textVariant="small" isCopiable />}
    />
    <Modal.Field
      mt={6}
      name="API Key name"
      content={
        <Input
          color="darkGray"
          size="sm"
          borderColor="gray"
          borderRadius={6}
          value={editedKey.name}
          onChange={onEditedKeyChange}
        />
      }
    />
  </>
)

export default EditApiKey
