import {
  BatchDetailRollupStatus,
  TransactionAggregatedListItemExitStatus,
  TransactionAggregatedListItemStatus,
} from 'generated/reactQueryClient'

export const PAGE_LENGTH = 10
export const FIRST_PAGE = 1

// If address is not a contract.
// https://ethereum.stackexchange.com/questions/28521/how-to-detect-if-an-address-is-a-contract.
export const STANDARD_ADDRESS_CODE = '0x'

export const TXN_STATUS_VARIANTS = {
  [TransactionAggregatedListItemStatus.successful]: 'success',
  [TransactionAggregatedListItemStatus.failed]: 'danger',
  [TransactionAggregatedListItemStatus.pending]: 'warning',
  [TransactionAggregatedListItemStatus.replaced]: 'primary',
} as const

export const EXIT_TXN_STATUS_VARIANTS = {
  [TransactionAggregatedListItemExitStatus.waiting]: 'warning',
  [TransactionAggregatedListItemExitStatus.ready]: 'link',
  [TransactionAggregatedListItemExitStatus.relayed]: 'secondary',
} as const

export const ROLLUP_STATUS_VARIANTS = {
  [BatchDetailRollupStatus['pre-committed']]: 'primary',
  [BatchDetailRollupStatus.committed]: 'primary',
  [BatchDetailRollupStatus.final]: 'success',
} as const

export const ERROR_MESSAGES = {
  web3ContractCode: 'Failed to load address code. Check if provided address hash is valid.',
  web3GetBlock: 'Failed to load a block. Check if provided block number is valid.',
  web3TransactionReceipt:
    'Failed to load transaction data. Check if provided transaction hash is valid.',
  webGetBlocks: 'Failed to load blocks.',
}

export const MAX_LIMIT_API_KEYS = 10
export const CONTRACT_INPUT_LIMIT_SIZE = 1024 * 1024 * 1.14 // Maximum input size limit in bytes (1.2MB).
