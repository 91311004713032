import React from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { IconButton, Input, InputGroup, InputGroupProps, InputRightElement } from '@chakra-ui/react'
import { useGetBatchById } from 'generated/reactQueryClient'
import web3qs from 'lib/web3qs'
import { useRouter } from 'next/router'
import { Routes } from 'constants/routes'
import { ARIA_LABEL } from 'utils/aria'

// A transaction hash is the longest string with 66 characters (address has 42 and block 6).
const SEARCH_BAR_MAX_LENGTH = 80

const SearchBar = (props: InputGroupProps) => {
  const { push } = useRouter()
  const [searchValue, setSearchValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const { refetch: fetchBatch } = useGetBatchById(searchValue, {
    query: {
      enabled: false,
    },
  })

  const handleRedirect = async () => {
    if (!searchValue) {
      return
    }

    if (web3qs.utils.isAddress(searchValue)) {
      void push(Routes.addressDetail({ hash: searchValue }))
    } else {
      // Batch does not support async call, so used promise. Inspiration: https://github.com/web3/web3.js/issues/1446#issuecomment-432360146.
      const batch = new web3qs.BatchRequest()
      // eslint-disable-next-line @typescript-eslint/unbound-method
      const promises = [web3qs.eth.getBlock, web3qs.eth.getTransaction].map(
        (call) =>
          new Promise((resolve) => {
            try {
              batch.add(
                // @ts-expect-error: missing type for request.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                call.request(searchValue, (_: unknown, response: unknown) => {
                  resolve(response)
                })
              )
            } catch {
              // Resolve as "null" after web3 service receives invalid input and catch is executed.
              resolve(null)
            }
          })
      )

      setIsLoading(true)

      batch.execute()
      const [block, transaction] = await Promise.all(promises)

      if (block) {
        void push(Routes.blockDetail({ id: searchValue }))
      } else if (transaction) {
        void push(Routes.transactionDetail({ txHash: searchValue, activeTab: 0 }))
      } else {
        const { data } = await fetchBatch()

        if (data) {
          void push(Routes.batchDetail({ id: searchValue }))
        } else {
          void push(Routes.searchError({ value: searchValue }))
        }
      }

      setIsLoading(false)
    }
  }

  return (
    <InputGroup {...props}>
      <Input
        maxLength={SEARCH_BAR_MAX_LENGTH}
        variant="search"
        size="xl"
        onKeyDown={(event) => {
          if (!isLoading && event.key === 'Enter') {
            void handleRedirect()
          }
        }}
        value={searchValue}
        onChange={(event) => {
          setSearchValue(event.target.value)
        }}
        placeholder="Search by Address / Transaction / Batch / Block"
      />
      <InputRightElement h="100%" mr={2.5}>
        <IconButton
          isLoading={isLoading}
          p={4}
          aria-label={ARIA_LABEL.searchIcon}
          icon={<Search2Icon pointerEvents="none" fontSize="xs" />}
          onClick={() => {
            void handleRedirect()
          }}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default SearchBar
