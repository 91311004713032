import React from 'react'
import { FaRegTrashAlt } from 'react-icons/fa'
import { MdOutlineEdit } from 'react-icons/md'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { ApiKey } from 'apollo/generated/graphqlClient'
import dayjs from 'dayjs'
import { MAX_LIMIT_API_KEYS } from 'constants/common'
import CopyToClipboard from 'components/CopyToClipboard'
import HashCard from 'components/HashCard'
import metamaskLogo from 'components/Icons/metamaskLogo.png'
import Modal from 'components/Modal'
import { ARIA_LABEL } from 'utils/aria'

type ViewApiKeysType = {
  address: string
  isConnected: boolean
  isAuthenticated: boolean
  onEditButtonClick: (apiKey: ApiKey) => void
  onRemoveButtonClick: ({ id }: { id: string }) => Promise<void> | void
  apiKeys: ApiKey[]
  areApiKeysLoading: boolean
  isDeletingApiKey: boolean
}

const ViewApiKeys = ({
  address,
  isAuthenticated,
  isConnected,
  apiKeys,
  areApiKeysLoading,
  isDeletingApiKey,
  onEditButtonClick,
  onRemoveButtonClick,
}: ViewApiKeysType) =>
  isAuthenticated ? (
    <>
      <Modal.Field
        name="Login address hash"
        content={<HashCard hash={address} textVariant="small" isCopiable />}
      />
      {areApiKeysLoading ? (
        <Skeleton mt={6} startColor="gray" h={50} />
      ) : (
        <Modal.Field
          mt={6}
          name="My API Keys"
          content={
            apiKeys.length > 0 ? (
              <Box mb={-4}>
                <Box maxH={96} overflowY="scroll">
                  {apiKeys.map((apiKey) => {
                    const { id, name, key, createdAt } = apiKey

                    return (
                      <Box key={id} py={2}>
                        <Text variant="regulardBold">{name}</Text>
                        <CopyToClipboard text={key} />
                        <Flex
                          pt={{ sm: 2, md: 0 }}
                          flexFlow="wrap"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text variant="small" color="darkGray">
                            Created at {dayjs(createdAt as string).format('YYYY-DD-MM HH:mm')}
                          </Text>
                          <Box mr={6}>
                            <Button
                              mr={2}
                              variant="tertiary"
                              bgColor="lightGray"
                              size="small"
                              fontSize="14px"
                              color="darkGray"
                              onClick={() => {
                                onEditButtonClick(apiKey)
                              }}
                            >
                              <Icon as={MdOutlineEdit} color="darkGray" boxSize={4} mr={2} />
                              Edit
                            </Button>
                            <IconButton
                              variant="tertiary"
                              bgColor="lightGray"
                              color="darkGray"
                              boxSize={8}
                              p={2}
                              aria-label={ARIA_LABEL.removeIcon}
                              icon={<FaRegTrashAlt />}
                              onClick={() => {
                                void onRemoveButtonClick({ id })
                              }}
                              isDisabled={isDeletingApiKey}
                            />
                          </Box>
                        </Flex>
                        <Divider size="3" color="lightGray" pt={4} />
                      </Box>
                    )
                  })}
                </Box>
                {apiKeys.length >= MAX_LIMIT_API_KEYS && (
                  <Text color="red" mt={4}>
                    {`You have reached the maximum limit of ${MAX_LIMIT_API_KEYS} API keys per user. To create a new key,
                    you must first delete an existing one.`}
                  </Text>
                )}
              </Box>
            ) : (
              <Text mt={3} variant="small" color="darkGray">
                You do not have any API Keys yet, please create a new one.
              </Text>
            )
          }
        />
      )}
    </>
  ) : (
    <Flex alignItems="center" flexDir="column" gap={10} mt={6}>
      <Heading variant="xs">
        For adding and managing your API Keys please {isConnected ? 'sign in' : 'connect'} to
        MetaMask
      </Heading>
      <Image alt="Metamask Logo" src={metamaskLogo.src} />
    </Flex>
  )

export default ViewApiKeys
