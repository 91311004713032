import React from 'react'
import { FaRegTimesCircle } from 'react-icons/fa'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Heading,
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { ARIA_LABEL } from 'utils/aria'

type ModalProps = {
  children: React.ReactNode
  loadingText?: string
  isOpen: boolean
  isPrimaryButtonDisabled?: boolean
  isPrimaryButtonLoading?: boolean
  isSecondaryButtonLoading?: boolean
  hasSecondaryButton?: boolean
  onClose: () => void
  onPrimaryButtonClick?: () => void
  onSecondaryButtonClick?: () => void
  primaryButtonLabel?: string
  secondaryButtonLabel?: string
  customPrimaryButton?: React.ReactNode
  onBackButtonClick?: () => void
  title: string
}

const Modal = ({
  children,
  loadingText,
  isOpen,
  isPrimaryButtonDisabled = false,
  isPrimaryButtonLoading = false,
  isSecondaryButtonLoading = false,
  hasSecondaryButton = true,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick = onClose,
  primaryButtonLabel = 'Confirm',
  secondaryButtonLabel = 'Cancel',
  customPrimaryButton,
  onBackButtonClick,
  title,
}: ModalProps) => {
  const contentRef = React.useRef(null)

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={contentRef}>
      <ModalOverlay />
      <ModalContent ref={contentRef} borderRadius={12} minW={{ md: 650, lg: 800 }}>
        <ModalHeader borderTopRadius="inherit" p={8} bgColor="secondary">
          <Flex minH={10} alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              {onBackButtonClick && (
                <IconButton
                  fontSize={40}
                  color="white"
                  bgColor="none"
                  _hover={{
                    bg: 'none',
                    color: 'titanGray',
                  }}
                  mr={2}
                  aria-label={ARIA_LABEL.searchIcon}
                  icon={<MdOutlineKeyboardArrowLeft />}
                  onClick={onBackButtonClick}
                />
              )}
              <Heading color="white" variant="xl">
                {title}
              </Heading>
            </Flex>
            <IconButton
              fontSize={30}
              onClick={onClose}
              bgColor="none"
              _hover={{
                bg: 'none',
                color: 'white',
              }}
              color="titanGray"
              aria-label={ARIA_LABEL.closeIcon}
              icon={<FaRegTimesCircle />}
            />
          </Flex>
        </ModalHeader>
        <ModalBody px={8} py={6}>
          {children}
        </ModalBody>
        <ModalFooter px={8} pb={8}>
          {hasSecondaryButton && (
            <Button
              variant="secondary"
              mr={4}
              onClick={onSecondaryButtonClick}
              isLoading={isSecondaryButtonLoading}
            >
              {secondaryButtonLabel}
            </Button>
          )}
          {customPrimaryButton || (
            <Button
              isLoading={isPrimaryButtonLoading}
              isDisabled={isPrimaryButtonDisabled}
              onClick={onPrimaryButtonClick}
              loadingText={loadingText}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  )
}

type FieldProps = {
  name: string
  content: React.ReactNode
} & BoxProps

const Field = ({ name, content, ...props }: FieldProps) => (
  <Box {...props}>
    <Heading variant="xs" mb={3}>
      {name}
    </Heading>
    {content}
  </Box>
)

Modal.Field = Field

export default Modal
