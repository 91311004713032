export const createQueryParams = (params?: Record<string, string | number | boolean>): string => {
  if (!params) {
    return ''
  }

  const queryParams = new URLSearchParams()
  Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => {
      queryParams.set(key, String(value))
    })

  return queryParams.toString()
}

export const withQueryParams = (
  url: string,
  queryParams?: Record<string, string | number | boolean>
) => {
  const queryString = createQueryParams(queryParams)
  return queryString ? `${url}?${queryString}` : url
}

export const Routes = {
  addressDetail: ({
    hash,
    activeTab,
    filter,
  }: {
    hash: string
    activeTab?: number
    filter?: string
  }) => withQueryParams(`/address/${hash}`, { activeTab: activeTab ?? 0, filter }),
  batches: () => '/batches',
  batchDetail: ({ id }: { id: string }) => `/batches/${id}`,
  blocks: () => '/blocks',
  blockDetail: ({ id }: { id: string }) => `/blocks/${id}`,
  messageRelayer: ({ txHash }: { txHash: string }) => `/message-relayer/${txHash}`,
  tokens: () => '/tokens',
  transactions: (params?: { batch?: number; block?: number | string }) =>
    withQueryParams('/transactions', params),
  transactionDetail: ({ txHash, activeTab }: { txHash: string; activeTab: number }) =>
    withQueryParams(`/transactions/${txHash}`, { activeTab }),
  transactionsEnqueued: () => '/transactions-enqueued',
  transactionsExit: () => '/transactions-exit',
  home: () => '/',
  searchError: ({ value }: { value: string }) => `/search/${value}`,
}
