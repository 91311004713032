import React from 'react'
import { CopyIcon } from '@chakra-ui/icons'
import { Text } from '@chakra-ui/react'

const SHOW_COPIED_TEXT_INTERVAL = 2000

type CopyToClipboardProps = {
  text: string
  textColor?: string
  variant?: string
}

const CopyToClipboard = ({ text, textColor, variant }: CopyToClipboardProps) => {
  const [isCopied, setIsCopied] = React.useState(false)

  return (
    <span>
      <Text as="span" wordBreak="break-word" color={textColor} variant={variant}>
        {text}
        {!isCopied && (
          <CopyIcon
            ml={1}
            color={textColor}
            onClick={() => {
              void navigator.clipboard.writeText(text)
              setIsCopied(true)

              setTimeout(() => {
                setIsCopied(false)
              }, SHOW_COPIED_TEXT_INTERVAL)
            }}
            _hover={{ cursor: 'pointer' }}
          />
        )}
      </Text>
      {isCopied && (
        <Text as="span" ml={1} color="green">
          COPIED!
        </Text>
      )}
    </span>
  )
}

export default CopyToClipboard
