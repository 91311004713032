/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Sudolabs - Quanstamp
 * Description
 * OpenAPI spec version: 1.0.11
 */
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { ErrorType } from '../utils/axios'
import { customAxiosInstance } from '../utils/axios'

export type GetInternalTransactionsParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
}

export type GetExitTransactions200 = {
  data?: TransactionExitList
  paginationContext?: PaginationContext
}

export type GetExitTransactionsParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
}

export type GetEnqueuedTransactions200 = {
  data?: TransactionEnqueuedList
  paginationContext?: PaginationContext
}

export type GetEnqueuedTransactionsParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
}

export type GetAggregatedTransactions200 = {
  data?: TransactionAggregatedList
  paginationContext?: PaginationContext
}

export type GetTransactions200 = {
  data?: TransactionList
  paginationContext?: PaginationContext
}

export type GetTransactionStats200 = {
  totalTxCount?: number
  txCountPerMinute?: number
}

export type GetBatches200 = {
  data?: BatchList
  paginationContext?: PaginationContext
}

export type GetLatestBatch200 = {
  batchIndex?: number
}

export type FilterTxTypeParamParameter =
  (typeof FilterTxTypeParamParameter)[keyof typeof FilterTxTypeParamParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterTxTypeParamParameter = {
  L2: 'L2',
  L1L2: 'L1L2',
  L2L1: 'L2L1',
} as const

/**
 * Filter results by address hash.
 */
export type FilterByAddressParamParameter = string

/**
 * Filter results by transactions hash.
 */
export type FilterByTxParamParameter = string

/**
 * Filter results by l2BlockIndex index.
 */
export type FilterByBlockParamParameter = string

/**
 * Filter results by batch index.
 */
export type FilterByBatchParamParameter = string

/**
 * Offset of results.
 */
export type OffsetParamParameter = number

/**
 * Max number of results.
 */
export type LimitParamParameter = number

export type GetAggregatedTransactionsParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
  /**
   * Filter results by batch index.
   */
  batch?: FilterByBatchParamParameter
  /**
   * Filter results by l2BlockIndex index.
   */
  block?: FilterByBlockParamParameter
  /**
   * Filter results by transactions hash.
   */
  tx?: FilterByTxParamParameter
  /**
   * Filter results by address hash.
   */
  address?: FilterByAddressParamParameter
  /**
   * Filter results by type.
   */
  txType?: FilterTxTypeParamParameter
}

export type GetTransactionsParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
  /**
   * Filter results by batch index.
   */
  batch?: FilterByBatchParamParameter
  /**
   * Filter results by transactions hash.
   */
  tx?: FilterByTxParamParameter
  /**
   * Filter results by address hash.
   */
  address?: FilterByAddressParamParameter
}

export type GetBatchesParams = {
  /**
   * Max number of results.
   */
  limit?: LimitParamParameter
  /**
   * Offset of results.
   */
  offset?: OffsetParamParameter
}

export type TransactionInternalListItem = {
  l2ParentTxhash?: string
  l2BlockIndex?: number
  timestamp?: number
  from?: string
  to?: string
  value?: number
  typeTraceAddress?: string
  gasLimit?: number
}

export type TransactionInternalList = TransactionInternalListItem[]

export type GetInternalTransactions200 = {
  data?: TransactionInternalList
  paginationContext?: PaginationContext
}

export type TransactionExitListItemStatus =
  (typeof TransactionExitListItemStatus)[keyof typeof TransactionExitListItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionExitListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const

export type TransactionExitListItemExitStatus =
  (typeof TransactionExitListItemExitStatus)[keyof typeof TransactionExitListItemExitStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionExitListItemExitStatus = {
  waiting: 'waiting',
  ready: 'ready',
  relayed: 'relayed',
} as const

export type TransactionExitListItem = {
  nonce?: number
  from?: string
  l1TxHash?: string
  l2TxHash?: string
  timestamp?: number
  exitStatus?: TransactionExitListItemExitStatus
  timeLeft?: number
  l1BlockIndex?: number
  l2BlockIndex?: number
  to?: string
  status?: TransactionExitListItemStatus
  value?: number
  fee?: number
}

export type TransactionExitList = TransactionExitListItem[]

export type TransactionEnqueuedListItemStatus =
  (typeof TransactionEnqueuedListItemStatus)[keyof typeof TransactionEnqueuedListItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionEnqueuedListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const

export type TransactionEnqueuedListItem = {
  l1BlockIndex?: number
  l1TxHash?: string
  l1TxOrigin?: string
  l2TxHash?: string
  queueIndex?: number
  timestamp?: number
  gasLimit?: number
  gasUsed?: number
  l2BlockIndex?: number
  to?: string
  status?: TransactionEnqueuedListItemStatus
  value?: number
  fee?: number
}

export type TransactionEnqueuedList = TransactionEnqueuedListItem[]

export type TransactionAggregatedListItemTxType =
  (typeof TransactionAggregatedListItemTxType)[keyof typeof TransactionAggregatedListItemTxType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAggregatedListItemTxType = {
  L2: 'L2',
  L1L2: 'L1L2',
  L2L1: 'L2L1',
} as const

export type TransactionAggregatedListItemExitStatus =
  (typeof TransactionAggregatedListItemExitStatus)[keyof typeof TransactionAggregatedListItemExitStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAggregatedListItemExitStatus = {
  waiting: 'waiting',
  ready: 'ready',
  relayed: 'relayed',
} as const

export type TransactionAggregatedListItemStatus =
  (typeof TransactionAggregatedListItemStatus)[keyof typeof TransactionAggregatedListItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAggregatedListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const

export type TransactionAggregatedListItem = {
  txIndex?: number
  l2TxHash?: string
  l1BlockIndex?: number
  l2BlockIndex?: number
  batchIndex?: number
  from?: string
  to?: string
  status?: TransactionAggregatedListItemStatus
  failureReason?: string
  timestamp?: number
  confirmedBy?: string
  l1TxHash?: string
  l1TxOrigin?: string
  queueIndex?: number
  gasLimit?: number
  gasUsed?: number
  nonce?: number
  exitStatus?: TransactionAggregatedListItemExitStatus
  timeLeft?: number
  value?: string
  fee?: string
  txType?: TransactionAggregatedListItemTxType
}

export type TransactionAggregatedList = TransactionAggregatedListItem[]

export type TransactionListItemStatus =
  (typeof TransactionListItemStatus)[keyof typeof TransactionListItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const

export type TransactionListItem = {
  batchIndex?: number
  txIndex?: number
  l2TxHash?: string
  l1BlockIndex?: number
  l2BlockIndex?: number
  from?: string
  to?: string
  status?: TransactionListItemStatus
  failureReason?: string
  timestamp?: number
  confirmedBy?: string
  value?: string
  fee?: string
}

export type TransactionList = TransactionListItem[]

export type BatchListItemRollupStatus =
  (typeof BatchListItemRollupStatus)[keyof typeof BatchListItemRollupStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchListItemRollupStatus = {
  'pre-committed': 'pre-committed',
  committed: 'committed',
  final: 'final',
} as const

export type BatchListItem = {
  batchIndex?: number
  l1Timestamp?: number
  txCount?: number
  l1TxHash?: string
  rollupStatus?: BatchListItemRollupStatus
  prevTotalElements?: number
}

export type BatchList = BatchListItem[]

export type BatchDetailRollupStatus =
  (typeof BatchDetailRollupStatus)[keyof typeof BatchDetailRollupStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchDetailRollupStatus = {
  'pre-committed': 'pre-committed',
  committed: 'committed',
  final: 'final',
} as const

export interface BatchDetail {
  batchIndex?: number
  l1Timestamp?: number
  txCount?: number
  l1TxHash?: string
  l1BlockIndex?: string
  batchRoot?: string
  rollupStatus?: BatchDetailRollupStatus
  extraData?: string
  proof?: string
  publicInputs?: string
  zkevmCircuits?: string
  proverVersion?: string
  prevTotalElements?: number
}

export interface PaginationContext {
  limit?: number
  offset?: number
  totalElements?: number
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * Get latest batch index. This call is being used in overview widget in the home page.
 */
export const getLatestBatch = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetLatestBatch200>({ url: `/batches/latest`, method: 'get', signal }, options)

export const getGetLatestBatchQueryKey = () => [`/batches/latest`]

export type GetLatestBatchQueryResult = NonNullable<Awaited<ReturnType<typeof getLatestBatch>>>
export type GetLatestBatchQueryError = ErrorType<unknown>

export const useGetLatestBatch = <
  TData = Awaited<ReturnType<typeof getLatestBatch>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getLatestBatch>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLatestBatchQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestBatch>>> = ({ signal }) =>
    getLatestBatch(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getLatestBatch>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get batch detail.
 */
export const getBatchById = (
  batchId: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<BatchDetail>({ url: `/batches/${batchId}`, method: 'get', signal }, options)

export const getGetBatchByIdQueryKey = (batchId: string) => [`/batches/${batchId}`]

export type GetBatchByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getBatchById>>>
export type GetBatchByIdQueryError = ErrorType<unknown>

export const useGetBatchById = <
  TData = Awaited<ReturnType<typeof getBatchById>>,
  TError = ErrorType<unknown>
>(
  batchId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBatchById>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBatchByIdQueryKey(batchId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatchById>>> = ({ signal }) =>
    getBatchById(batchId, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBatchById>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get list of batches
 */
export const getBatches = (
  params?: GetBatchesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => customAxiosInstance<GetBatches200>({ url: `/batches`, method: 'get', params, signal }, options)

export const getGetBatchesQueryKey = (params?: GetBatchesParams) => [
  `/batches`,
  ...(params ? [params] : []),
]

export type GetBatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getBatches>>>
export type GetBatchesQueryError = ErrorType<unknown>

export const useGetBatches = <
  TData = Awaited<ReturnType<typeof getBatches>>,
  TError = ErrorType<unknown>
>(
  params?: GetBatchesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBatches>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBatchesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatches>>> = ({ signal }) =>
    getBatches(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getBatches>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get transaction stats. This call is being used in overview widget in the home page
 */
export const getTransactionStats = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetTransactionStats200>(
    { url: `/transaction-stats`, method: 'get', signal },
    options
  )

export const getGetTransactionStatsQueryKey = () => [`/transaction-stats`]

export type GetTransactionStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionStats>>
>
export type GetTransactionStatsQueryError = ErrorType<unknown>

export const useGetTransactionStats = <
  TData = Awaited<ReturnType<typeof getTransactionStats>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactionStats>>, TError, TData>
  request?: SecondParameter<typeof customAxiosInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTransactionStatsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionStats>>> = ({ signal }) =>
    getTransactionStats(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTransactionStats>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getTransactions = (
  params?: GetTransactionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetTransactions200>(
    { url: `/transactions/l2`, method: 'get', params, signal },
    options
  )

export const getGetTransactionsQueryKey = (params?: GetTransactionsParams) => [
  `/transactions/l2`,
  ...(params ? [params] : []),
]

export type GetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactions>>>
export type GetTransactionsQueryError = ErrorType<unknown>

export const useGetTransactions = <
  TData = Awaited<ReturnType<typeof getTransactions>>,
  TError = ErrorType<unknown>
>(
  params?: GetTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({ signal }) =>
    getTransactions(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTransactions>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getAggregatedTransactions = (
  params?: GetAggregatedTransactionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetAggregatedTransactions200>(
    { url: `/transactions`, method: 'get', params, signal },
    options
  )

export const getGetAggregatedTransactionsQueryKey = (params?: GetAggregatedTransactionsParams) => [
  `/transactions`,
  ...(params ? [params] : []),
]

export type GetAggregatedTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAggregatedTransactions>>
>
export type GetAggregatedTransactionsQueryError = ErrorType<unknown>

export const useGetAggregatedTransactions = <
  TData = Awaited<ReturnType<typeof getAggregatedTransactions>>,
  TError = ErrorType<unknown>
>(
  params?: GetAggregatedTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAggregatedTransactions>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAggregatedTransactionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAggregatedTransactions>>> = ({
    signal,
  }) => getAggregatedTransactions(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getAggregatedTransactions>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getEnqueuedTransactions = (
  params?: GetEnqueuedTransactionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetEnqueuedTransactions200>(
    { url: `/transactions/enqueued`, method: 'get', params, signal },
    options
  )

export const getGetEnqueuedTransactionsQueryKey = (params?: GetEnqueuedTransactionsParams) => [
  `/transactions/enqueued`,
  ...(params ? [params] : []),
]

export type GetEnqueuedTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEnqueuedTransactions>>
>
export type GetEnqueuedTransactionsQueryError = ErrorType<unknown>

export const useGetEnqueuedTransactions = <
  TData = Awaited<ReturnType<typeof getEnqueuedTransactions>>,
  TError = ErrorType<unknown>
>(
  params?: GetEnqueuedTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEnqueuedTransactions>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEnqueuedTransactionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnqueuedTransactions>>> = ({
    signal,
  }) => getEnqueuedTransactions(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEnqueuedTransactions>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getExitTransactions = (
  params?: GetExitTransactionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetExitTransactions200>(
    { url: `/transactions/exit`, method: 'get', params, signal },
    options
  )

export const getGetExitTransactionsQueryKey = (params?: GetExitTransactionsParams) => [
  `/transactions/exit`,
  ...(params ? [params] : []),
]

export type GetExitTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExitTransactions>>
>
export type GetExitTransactionsQueryError = ErrorType<unknown>

export const useGetExitTransactions = <
  TData = Awaited<ReturnType<typeof getExitTransactions>>,
  TError = ErrorType<unknown>
>(
  params?: GetExitTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExitTransactions>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExitTransactionsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExitTransactions>>> = ({ signal }) =>
    getExitTransactions(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getExitTransactions>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const getInternalTransactions = (
  txHash: string,
  params?: GetInternalTransactionsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) =>
  customAxiosInstance<GetInternalTransactions200>(
    { url: `/transactions/${txHash}/internal`, method: 'get', params, signal },
    options
  )

export const getGetInternalTransactionsQueryKey = (
  txHash: string,
  params?: GetInternalTransactionsParams
) => [`/transactions/${txHash}/internal`, ...(params ? [params] : [])]

export type GetInternalTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInternalTransactions>>
>
export type GetInternalTransactionsQueryError = ErrorType<unknown>

export const useGetInternalTransactions = <
  TData = Awaited<ReturnType<typeof getInternalTransactions>>,
  TError = ErrorType<unknown>
>(
  txHash: string,
  params?: GetInternalTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getInternalTransactions>>, TError, TData>
    request?: SecondParameter<typeof customAxiosInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetInternalTransactionsQueryKey(txHash, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInternalTransactions>>> = ({
    signal,
  }) => getInternalTransactions(txHash, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof getInternalTransactions>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!txHash,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}
